import DocumentService from '@/services/document.service'
import { NotificationStore } from '@/store/notification.store'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { AxiosError } from 'axios'

export const useCreateDocument = (
    preventOpeningDocument = false,
    preventRefetching = false
) => {
    const { $documentService, $notificationStore } = useNuxtApp()
    const documentService = $documentService as DocumentService
    const notificationStore = $notificationStore as NotificationStore

    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: documentService.createDocument,
        onError: (err: AxiosError) => {
            if (err?.response?.status === 402) {
                navigateTo(`/pricing`)
                return
            }
            notificationStore.notifyError({
                summary: 'Error creating a new document',
                detail: '',
                life: 3000,
            })
        },
        onSuccess(data) {
            // TODO: fix this create document types
            if (!preventRefetching)
                queryClient.invalidateQueries({
                    queryKey: ['documents'],
                })
            if (!preventOpeningDocument) navigateTo(`/d/${data?.documentId}`)
        },
    })
}
