<script setup lang="ts">
import { ref } from 'vue'
import { NotificationStore } from '@/store/notification.store'
import {
    FileInfo,
    SelectedEventArgs,
    Uploader,
    UploaderComponent,
    UploadingEventArgs,
} from '@syncfusion/ej2-vue-inputs'
import ProgressBar from 'primevue/progressbar'
import generateRandomString from '@/utils/helpers/generateRandomString'

export interface CustomFileInfo extends FileInfo {
    uploadProgress: number
    uploaded: boolean
    uploading?: boolean
    failed?: boolean
}

const props = withDefaults(
    defineProps<{
        uploaderProps: InstanceType<typeof UploaderComponent>['$props']
        showUploadButton: boolean
    }>(),
    {
        showUploadButton: true,
    }
)

const emit = defineEmits<{
    fileUploaded: [file: FileInfo, e: ProgressEvent, cb: () => void]
}>()

const uploaderRef = ref<Uploader>()

defineExpose({
    uploaderRef,
})

const { $notificationStore } = useNuxtApp()
const notificationStore = $notificationStore as NotificationStore
const filesList = ref<CustomFileInfo[]>([])
const fileListObj = ref<{ [key: string]: CustomFileInfo }>({})
const fileUploading = ref(false)

watch(fileListObj, (val) => {
    fileUploading.value = Object.values(val).some(
        (file) => file.uploading === true
    )
})

const allowedExtensions = computed(() => {
    return (
        props.uploaderProps.allowedExtensions
            ?.split(',')
            .map((ext) => ext.trim().replace('.', '')) ?? []
    )
})

const dropAreaId = computed(() => `drop${generateRandomString(44)}`)

const isValidInput = (files: FileInfo[]) => {
    return files.every((file) => {
        return allowedExtensions?.value.includes(file.type)
    })
}

const showInvalidFilesError = (files: FileInfo[]) => {
    const invalidFiles = files.filter(
        (file) =>
            !allowedExtensions.value?.includes(file.type) || file.status === '0'
    )

    invalidFiles.forEach((file) => {
        notificationStore.notifyError({
            summary: file.name,
            detail: file.status,
            life: 3000,
        })
    })
}

const onFailure = ({ e, file }: { e: ProgressEvent; file: CustomFileInfo }) => {
    const fileId = file.id as string
    fileListObj.value = {
        ...fileListObj.value,
        [fileId]: {
            ...fileListObj.value[fileId],
            failed: true,
            uploading: false,
            uploaded: true,
        },
    }
    if (props.uploaderProps.failure) props?.uploaderProps?.failure({ e, file })
    notificationStore.notifyError({
        summary: file.name,
        detail: file.status,
        life: 3000,
    })
}

const onUpload = ({
    e,
    file,
    name,
    operation,
}: {
    e: ProgressEvent
    file: FileInfo
    name: string
    operation: string
}) => {
    const fileId = file.id as string
    fileListObj.value = {
        ...fileListObj.value,
        [fileId]: {
            ...fileListObj.value[fileId],
            uploadProgress: Math.round((e.loaded / e.total) * 100),
        },
    }
}

const onUploading = (e: UploadingEventArgs) => {
    const fileId = e.fileData.id as string
    fileListObj.value = {
        ...fileListObj.value,
        [fileId]: { ...fileListObj.value[fileId], uploading: true },
    }
    if (props.uploaderProps.uploading) props?.uploaderProps?.uploading(e)
}

const onUploadSuccess = ({ e, file }: { e: ProgressEvent; file: FileInfo }) => {
    const fileId = file.id as string
    if (!e) {
        filesList.value = []
        return
    }
    emit('fileUploaded', file, e, () => {
        fileListObj.value = {
            ...fileListObj.value,
            [fileId]: {
                ...fileListObj.value[fileId],
                uploaded: true,
                uploading: false,
            },
        }
    })
}

const onFileSelect = (e: SelectedEventArgs) => {
    if (!isValidInput(e.filesData)) {
        showInvalidFilesError(e.filesData)
        return
    }

    const filesObj = e.filesData.reduce(
        (acc: { [key: string]: CustomFileInfo }, curr) => {
            acc[curr.id as string] = {
                ...curr,
                uploadProgress: 0,
                uploaded: false,
            }
            return acc
        },
        {}
    )

    filesList.value = [...filesList.value, ...Object.values(filesObj)]
}

const dropzoneCreated = () => {
    const dropzoneEl = document.querySelector<HTMLElement>(
        `#${dropAreaId.value}`
    )
    dropzoneEl?.addEventListener('click', function (e) {
        e.preventDefault()
        if (fileUploading.value) return
        const uploaderEl = dropzoneEl.previousElementSibling
        if (!uploaderEl) return

        const fileSelection =
            uploaderEl.getElementsByClassName('e-file-select-wrap')

        if (fileSelection.length) {
            fileSelection[0].querySelector('button')?.click()
        }
        return false
    })
}

const removeFile = (e: MouseEvent, file: FileInfo) => {
    e.preventDefault()
    e.stopPropagation()
    filesList.value = filesList.value.filter((item) => item.id !== file.id)
    fileListObj.value = Object.keys(fileListObj.value)
        .filter((fileId) => fileId !== file.id)
        .reduce((acc: { [key: string]: CustomFileInfo }, fileId) => {
            acc[fileId] = fileListObj.value[fileId]
            return acc
        }, {})

    uploaderRef.value?.remove(file)
}

const clear = (e: MouseEvent) => {
    e.stopPropagation()
    filesList.value = []
    fileListObj.value = {}
    uploaderRef.value?.clearAll()
}
</script>

<template>
    <UploaderComponent
        v-bind="uploaderProps"
        ref="uploaderRef"
        :failure="onFailure"
        :uploading="onUploading"
        :auto-upload="false"
        :created="dropzoneCreated"
        :selected="onFileSelect"
        :progress="onUpload"
        :success="onUploadSuccess"
        :dropArea="`#${dropAreaId}`"
    />

    <div
        class="h-full cursor-pointer border-round-md overflow-hidden"
        :id="dropAreaId"
    >
        <div
            class="bg-gray-200 h-full p-3 flex flex-column"
            v-if="filesList.length > 0"
        >
            <div
                :style="{
                    maxHeight: '250px',
                }"
                class="flex overflow-y-scroll flex-grow-1 flex-wrap p-0 gap-3"
            >
                <div v-for="file in filesList" :key="file.id" class="w-10rem">
                    <div
                        class="bg-white border-round-lg h-12rem flex flex-column gap-2 p-2 justify-content-center align-items-center relative"
                    >
                        <Button
                            @click="removeFile($event, file)"
                            v-tooltip="{
                                value: 'Remove file',
                                pt: { root: { class: 'text-sm' } },
                            }"
                            icon="pi pi-times"
                            severity="danger"
                            rounded
                            class="w-1rem h-1rem p-0 absolute top-0 right-0 mt-2 mr-2"
                            icon-class=""
                        ></Button>
                        <span
                            class="font-semibold text-md white-space-nowrap text-overflow-ellipsis overflow-hidden w-full"
                        >
                            {{ file.name }}
                        </span>
                        <span class="font-semibold text-md">{{
                            uploaderRef?.bytesToSize(file.size)
                        }}</span>
                        <ProgressBar
                            class="w-full h-1rem"
                            :pt="{
                                value: {
                                    class: fileListObj[file.id as string]
                                        ?.failed
                                        ? 'bg-red-600'
                                        : 'bg-primary',
                                },
                            }"
                            :value="
                                fileListObj[file.id as string]
                                    ?.uploadProgress ?? 0
                            "
                        >
                        </ProgressBar>
                        <small
                            class="text-primary"
                            v-if="fileListObj[file.id as string]?.uploading"
                            >Uploading...</small
                        >
                        <small
                            v-else-if="
                                fileListObj[file.id as string]?.uploaded &&
                                !fileListObj[file.id as string]?.failed
                            "
                            class="text-green-600"
                            style="margin: 0 -3px"
                            >Uploaded successfully</small
                        >
                        <small
                            v-else-if="fileListObj[file.id as string]?.failed"
                            class="text-red-600"
                            >Failed to Upload</small
                        >
                        <small v-else>{{ file.status }}</small>
                    </div>
                </div>
            </div>
            <div class="flex justify-content-end gap-2 pt-2">
                <Button
                    :disabled="fileUploading"
                    :loading="fileUploading"
                    @click="clear"
                    size="small"
                    severity="secondary"
                    >Clear</Button
                >
                <Button
                    v-show="showUploadButton"
                    :loading="fileUploading"
                    @click.stop="uploaderRef?.upload()"
                    size="small"
                >
                    {{ fileUploading ? 'Uploading...' : 'Upload' }}
                </Button>
            </div>
        </div>
        <label
            v-else
            class="flex flex-column flex-grow-1 select-none justify-content-center p-4 align-items-center h-full cursor-pointer bg-gray-200 hover:bg-gray-300 transition-colors transition-duration-100"
        >
            <div
                class="flex flex-column align-items-center justify-content-center flex-grow-1"
            >
                <i class="pi pi-cloud-upload text-6xl mb-2"></i>
                <p class="mb-2 text-md">
                    <span class="font-semibold">Click to upload</span> or drag
                    and drop
                </p>
                <p class="text-xs text-gray-600 uppercase">
                    {{ props.uploaderProps.allowedExtensions }}
                </p>
                <slot></slot>
            </div>
            <!-- <Button
                @click="clear"
                size="small"
                class="ml-auto"
                severity="secondary"
                >Clear</Button
            > -->
        </label>
    </div>
</template>

<style>
@import '@syncfusion/ej2-base/styles/fabric.css';
@import '@syncfusion/ej2-buttons/styles/fabric.css';
@import '@syncfusion/ej2-vue-inputs/styles/fabric.css';
</style>

<style lang="scss">
.e-upload {
    display: none;

    .e-file-select-wrap {
        display: none;
    }
}
.note {
    color: red;
}
</style>
