export default function generateRandomString(len: number) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_";
  let result = "";
  for (let i = 0; i < len; i++) {
    const rIdx = Math.floor(Math.random() * characters.length);
    result += characters.charAt(rIdx);
  }
  return result;
}
